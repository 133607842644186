@use '@shared/util-styling/mixins';

.section {
  $this: &;

  max-width: var(--section-content-max-width);
  position: relative;
  margin: var(--spacing-32) auto;
  display: grid;
  gap: var(--spacing-24);

  &--separator-top {
    border-top: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-02);
    padding-top: var(--spacing-56);
  }

  &--separator-bottom {
    border-bottom: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-02);
    padding-bottom: var(--spacing-56);
  }

  &__image {
    @include mixins.next-image-contained;
  }

  &__text-container {
    align-self: center;

    h2 {
      margin-bottom: var(--spacing-24);
    }
  }

  &__image-container {
    align-self: start;
  }

  @include mixins.breakpoint('medium') {
    gap: 0;
    grid-template-columns: repeat(2, 1fr);
    margin: var(--spacing-56) auto;

    &__image-container {
      order: 1;
    }

    &__text-container {
      padding-right: var(--spacing-40);
    }

    &--reversed {
      #{$this}__image-container {
        order: -1;
      }

      #{$this}__text-container {
        padding-right: 0;
        padding-left: var(--spacing-40);
      }
    }
  }

  &__theme-secondary {
    h2 {
      color: var(--mdrt-color-text-on-dark-primary);
    }
    p {
      color: var(--mdrt-color-text-on-dark-secondary);
    }
    background-color: var(--mdrt-color-backgrounds-bg-primary-fill-03);

    #{$this}__text-container {
      padding: 0 var(--spacing-16) var(--spacing-32);
    }

    @include mixins.breakpoint('medium') {
      #{$this}__text-container {
        padding: var(--spacing-40);
      }
    }
  }
}
