@use '@shared/util-styling/mixins';

.contacts {
  margin-top: var(--spacing-24);
  display: grid;

  @include mixins.breakpoint('small') {
    grid-template-columns: repeat(auto-fit, minMax(200px, 1fr));
  }

  &__contact-block {
    margin-bottom: var(--spacing-24);
  }

  &__region-title {
    font: var(--mdrt-title-2);
    margin-bottom: var(--spacing-16);
  }

  &__contact-data {
    font: var(--mdrt-title-3);
    margin-bottom: var(--spacing-8);

    a {
      color: var(--mdrt-color-text-on-light-action);
    }
  }
}
