@use '@shared/util-styling/mixins';

.hero-section-content-wrapper {
  $_gradient-start: var(--mdrt-color-backgrounds-bg-primary-fill-01);
  $_gradient-end: var(--mdrt-color-backgrounds-bg-primary-fill-missing);

  height: 100%;
  padding-bottom: 60px;
  position: relative;

  &::before {
    background: linear-gradient($_gradient-start, $_gradient-end);
    content: '';
    min-height: 120%;
    position: absolute;
    top: 0;
    width: 100%;

    @include mixins.breakpoint('medium') {
      min-height: 130%;
    }
  }

  @include mixins.breakpoint('medium') {
    padding-bottom: 160px;
  }
}

.content {
  word-break: break-word;

  @include mixins.breakpoint('large') {
    max-width: 740px;
  }
}

.background-graphics {
  display: none;
  height: 430px;
  position: absolute;
  right: 0;
  top: 32px;
  width: 396px;

  @include mixins.breakpoint('large') {
    display: block;
  }
}
