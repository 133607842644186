@use '@shared/util-styling/mixins';

.requirements-wrapper {
  position: relative;
  width: 100%;
}

.note-wrapper {
  background-color: var(--mdrt-color-backgrounds-bg-surface-02);
  padding: var(--spacing-32) var(--spacing-32) 0 var(--spacing-32);
}

.error-message {
  font: var(--mdrt-title-2);
}

.header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);
  margin-bottom: var(--spacing-2a4);
  width: 100%;

  &__dropdown-skeleton {
    height: 48px;
  }

  @include mixins.breakpoint('medium') {
    flex-direction: row;

    &__country-selector {
      margin-left: auto;
      width: 279px;
    }

    &__year-selector {
      width: 137px;
    }
  }
}

.production-table {
  min-width: 672px;

  &--sticky {
    position: sticky;
    left: -5px;
    background-color: var(--mdrt-color-backgrounds-bg-surface);
    border-right: 1px solid var(--mdrt-color-borders-dark-02);

    @include mixins.breakpoint('medium') {
      position: relative;
      background-color: transparent;
      border-right: unset;
      left: unset;
    }
  }

  &:focus-visible {
    outline: 2px solid;
    outline-offset: 0;
  }
}

.container {
  margin: 0;
  padding: 0;
}
