@use '@shared/util-styling/mixins';

.person-scoped-section-header {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--spacing-20);
  gap: var(--spacing-20);

  &__actions {
    display: flex;
    align-self: center;
    gap: var(--spacing-20);
    padding-bottom: var(--spacing-20);
  }
}

.person-data-table {
  margin-top: 0;
}

@include mixins.breakpoint('small') {
  .person-scoped-section-header {
    &__button {
      width: fit-content;
    }
  }
}

@include mixins.breakpoint('large') {
  .person-scoped-section-header {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0;
  }
}
