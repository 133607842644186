@use '@shared/util-styling/mixins';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  &__image {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 320px;

    &--compact {
      height: 160px;
    }
  }
}
