.flexColumn {
  display: flex;
  flex-direction: column;
}

.requirements {
  @extend .flexColumn;
  margin-top: var(--spacing-24);
  gap: var(--spacing-24);
  border-bottom: 1px solid var(--mdrt-color-borders-dark-02);
  padding-bottom: var(--spacing-56);

  h3 {
    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-title-1);
  }

  &__note-wrapper {
    @extend .flexColumn;
    gap: var(--spacing-8);
    background-color: var(--mdrt-color-backgrounds-bg-surface-02);
    padding: var(--spacing-16);
    border-radius: var(--mdrt-corner-radius-m);

    &--block {
      @extend .flexColumn;
      gap: var(--spacing-8);
    }
  }

  &__conversion-factors-wrapper {
    @extend .flexColumn;
    gap: var(--spacing-8);

    &--block {
      @extend .flexColumn;
      gap: var(--spacing-8);

      p {
        color: var(--mdrt-color-text-on-light-secondary);
        font: var(--mdrt-body-3-long);
      }
    }
  }

  &__monthly-goals-wrapper {
    @extend .flexColumn;
    gap: var(--spacing-8);

    p {
      color: var(--mdrt-color-text-on-light-primary);
      font: var(--mdrt-body-3-long);
    }
  }
}

.skeleton {
  height: 150px;
}
