@use '../mixins/responsive' as responsive;

@mixin button-group-base {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);

  @include responsive.breakpoint('medium') {
    flex-direction: row;
  }
}
