.trigger {
  align-items: center;
  display: flex;
  gap: var(--spacing-8);
  padding: 0 var(--spacing-8);

  &__icon {
    height: 24px;
    width: 24px;
  }

  &__text {
    font: var(--mdrt-title-3);
    white-space: nowrap;
  }
}
