@use '@shared/util-styling/mixins';

.assessment-form {
  &__list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-32);
    position: relative;
    font-weight: var(--mdrt-font-weights-nunito-sans-1);

    li {
      @include mixins.breakpoint('small') {
        padding-left: var(--spacing-20);
      }
    }

    @include mixins.breakpoint('small') {
      list-style-type: decimal;
      padding-left: var(--spacing-40);
    }
  }
}
