.filter-options-item {
  border-radius: var(--mdrt-corner-radius-s);
  padding: var(--mdrt-spacing-04) 0;

  label {
    color: var(--mdrt-color-text-on-light-primary);
  }

  &:hover {
    background-color: var(--mdrt-color-backgrounds-bg-neutral-fill);
  }
}
