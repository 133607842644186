.detail-page-render-html {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font: var(--mdrt-headline-4);
    margin-block: var(--spacing-24) var(--spacing-12);

    strong {
      font-family: inherit;
    }
  }

  p {
    margin-block: var(--spacing-16);
  }

  ul,
  ol {
    display: block;
    list-style: disc outside none;
    margin: var(--spacing-16) 0;
    padding-left: var(--spacing-40);
  }

  ol {
    list-style-type: decimal;
  }

  a {
    color: var(--mdrt-color-text-on-light-action);
  }

  img {
    max-width: 100%;
  }

  > :last-child {
    margin-bottom: 0;
  }

  > div:last-child > :last-child {
    margin-bottom: 0;
  }
}
