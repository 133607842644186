@use '@shared/util-styling/mixins';
@use '@shared/util-styling/tokens';

$wrapper-padding-desktop: var(--spacing-8);
$wrapper-margin-top-desktop: var(--spacing-16);
$sidebar-width: 324px;

.wrapper {
  margin-top: var(--spacing-8);

  @include mixins.transition {
    transition: padding 0.2s ease-in;
  }

  @include mixins.breakpoint('medium') {
    $_margin: calc($wrapper-padding-desktop * -1);

    align-items: flex-start;
    contain: paint;
    display: flex;
    flex-direction: column;
    margin: $wrapper-margin-top-desktop $_margin $_margin $_margin;
    padding: $wrapper-padding-desktop;
    position: relative;
  }

  &--filters-open {
    @include mixins.breakpoint('medium') {
      padding-right: calc($sidebar-width + $wrapper-margin-top-desktop + $wrapper-padding-desktop);
    }
  }
}

.toolbar.toolbar {
  display: flex;
  gap: var(--spacing-8);
  margin-bottom: var(--spacing-8);
  margin-top: var(--spacing-8);
  max-width: 100%;

  &__filter-button {
    background: tokens.$mdrt-filter-button-bg-fill;
    border: 1px solid tokens.$mdrt-filter-button-border-fill;
    color: tokens.$mdrt-filter-button-text-fill;

    &:hover {
      background: tokens.$mdrt-filter-button-bg-fill;
      border-color: tokens.$mdrt-filter-button-border-hover;
      color: tokens.$mdrt-filter-button-text-fill;
    }
  }

  button {
    white-space: nowrap;
  }
}

.filters-button-wrapper {
  align-items: center;
  display: flex;
  flex-shrink: 0;

  &::after {
    background-color: var(--mdrt-color-borders-dark-02);
    content: '';
    display: block;
    height: 32px;
    margin-left: var(--spacing-8);
    width: 1px;
  }
}

.filters-wrapper {
  height: calc(100% - $wrapper-margin-top-desktop);
  position: absolute;
  right: var(--spacing-8);
  z-index: 1;
}

.filters {
  background-color: var(--mdrt-color-backgrounds-bg-surface);
  visibility: collapse;

  @include mixins.breakpoint('medium') {
    &:not(&--is-open) {
      @include mixins.visually-hidden;

      height: 0;
      position: static;
    }

    align-self: start;
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--app-header-height-secondary) - $wrapper-margin-top-desktop);
    max-height: 100%;
    opacity: 0;
    position: sticky;
    top: calc(var(--app-header-height-secondary) + $wrapper-margin-top-desktop);
    width: 0;

    @include mixins.transition {
      transition: opacity 0.2s ease-in, width 0.2s ease-in 0.05s, visibility 0.2s ease-in;
    }
  }

  &--is-open {
    visibility: visible;

    @include mixins.breakpoint('medium') {
      opacity: 1;
      width: $sidebar-width;

      @include mixins.transition {
        transition: opacity 0.2s ease-in 0.1s, width 0.2s ease-in;
      }
    }
  }

  &--mobile {
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    visibility: visible;
    width: 100%;
  }
}

.loader {
  margin: 0 calc(var(--mdrt-spacing-08) * -1);
}

.alert {
  margin-top: var(--spacing-8);
  width: 100%;
}
