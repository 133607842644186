.text-block {
  display: flex;
  gap: var(--spacing-12);
  margin: var(--spacing-56) 0;
  background-color: var(--mdrt-color-backgrounds-bg-surface-03);
  font: var(--mdrt-title-2);
  padding: var(--spacing-32);
  justify-content: center;
  align-items: center;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.icon {
  width: var(--spacing-24);

  &__border {
    border-radius: var(--mdrt-corner-radius-xxl);
    border: 1px solid var(--mdrt-color-borders-dark-01);
    background: #ffffff;
    min-width: var(--spacing-48);
    height: var(--spacing-48);
    margin-right: var(--spacing-8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
