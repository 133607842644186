@use '@shared/util-styling/mixins';

.hero-section-company-admin {
  border-top: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-02);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);
  margin-top: var(--spacing-20);
  max-width: 476px;
  padding-top: var(--spacing-12);

  @include mixins.breakpoint('small') {
    flex-direction: row;
  }

  &__description {
    color: var(--mdrt-color-text-on-dark-secondary);
    font: var(--mdrt-title-3);
  }

  &__action {
    align-self: flex-start;
    white-space: nowrap;
  }
}
