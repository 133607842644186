@use '@shared/util-styling/mixins';
@use '@shared/util-styling/tokens';

.section {
  border-bottom: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-02);
  border-top: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-02);
  padding: var(--spacing-32) var(--spacing-16);
  margin: var(--spacing-32) 0;

  @include mixins.breakpoint('medium') {
    padding: var(--spacing-32) 0;
  }

  &__content-wrapper {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-24);
    justify-content: flex-start;

    @include mixins.breakpoint('medium') {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      gap: var(--spacing-56);
      padding: var(--spacing-16) var(--spacing-24);
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
    justify-content: flex-start;
  }

  &__button-wrapper {
    font: var(--mdrt-special-text-button-1);
    text-wrap: nowrap;
  }

  &__header {
    font: var(--mdrt-headline-2);
  }

  &__subHeader {
    font: var(--mdrt-title-2);
  }
}
