@use '@shared/util-styling/mixins';

.dead-letters-section {
  &__tables-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-40);
  }
}

.dead-letters-section-header {
  &__actions {
    padding-bottom: var(--spacing-20);
  }

  &__button {
    width: 100%;
  }
}

@include mixins.breakpoint('small') {
  .dead-letters-section-header {
    &__button {
      width: fit-content;
    }
  }
}

@include mixins.breakpoint('large') {
  .dead-letters-section-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &__actions {
      padding-bottom: 0;
    }
  }
}
