@use '@shared/util-styling/mixins';

.table-actions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-20);

  &__input {
    padding: var(--spacing-10);
  }
}

@include mixins.breakpoint('medium') {
  .table-actions {
    flex-direction: row;
  }
}
