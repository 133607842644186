@use '@shared/util-styling/mixins';

.hero-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  padding-top: 34px;

  &__summary-container {
    @include mixins.breakpoint('medium') {
      padding-top: var(--spacing-16);
    }
  }

  @include mixins.breakpoint('medium') {
    flex-direction: row;
    margin-bottom: 0;
    padding-top: 108px;
  }
}
