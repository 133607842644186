@use '@shared/util-styling/mixins';

$_spacing-between-items: var(--spacing-24);

.wrapper {
  display: flex;
  width: 100%;

  & + & {
    @include mixins.breakpoint('medium', max) {
      margin-top: calc(var(--spacing-24) * -1);
    }
  }
}

.carousel {
  $_this: &;

  &__header-wrapper {
    margin: 0 auto;
    max-width: var(--content-max-width);
    min-width: var(--container-min-width);
    width: 100%;
  }

  &__wrapper {
    overflow: hidden;
    padding: var(--spacing-24) 0;
    padding-inline: var(--container-spacing-inline);
    position: relative;
    width: 100%;

    @include mixins.breakpoint('xlarge') {
      padding: var(--spacing-24) var(--spacing-32);
    }
  }

  &__container {
    margin-right: $_spacing-between-items;
    max-width: var(--content-max-width-wide);
    overflow: visible;

    &:global(.react-multi-carousel-list) {
      // making sure the overflow property is overridden, in case style sheet import order is broken due to known NEXT issue https://github.com/vercel/next.js/issues/51030
      overflow: visible;
    }
  }

  &__item-wrapper > * {
    width: calc(100% - $_spacing-between-items);
  }

  &__header-container {
    align-items: flex-end;
    display: flex;
    gap: var(--spacing-16);
    margin-bottom: var(--spacing-20);

    &:not(&--secondary) {
      @include mixins.breakpoint('medium', max) {
        margin-bottom: var(--spacing-12);
      }
    }
  }

  &__title {
    color: var(--mdrt-color-text-on-dark-primary);
    font: var(--mdrt-headline-2);

    &:not(&--secondary) {
      color: var(--mdrt-color-text-on-light-primary);

      @include mixins.breakpoint('medium', max) {
        font: var(--carousel-primary-title-font);
      }
    }
  }

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-16);
    min-width: 0;

    @include mixins.breakpoint('medium', max) {
      gap: var(--spacing-12);
    }

    @include mixins.breakpoint('small', max) {
      max-width: 100%;
    }
  }
}
