@use '@shared/util-styling/tokens';
@use '@shared/util-styling/mixins';

$transition: all 0.2s ease-in;

.learn-content-carousel-card {
  $this: &;

  border-radius: tokens.$mdrt-card-radius-small;
  height: 264px;

  @include mixins.breakpoint('medium') {
    border-radius: tokens.$mdrt-card-radius-large;
  }

  @mixin hover-effect {
    #{$this}__image {
      height: 92px;
      transition: $transition;

      @include mixins.breakpoint('medium') {
        height: 204px;
      }

      &::after {
        opacity: 1;
      }
    }

    #{$this}__description {
      opacity: 1;
      transform: translateY(0);
    }

    #{$this}__number {
      bottom: calc(6px * -1);
      transition: $transition;
    }
  }

  @include mixins.breakpoint('medium') {
    height: 396px;
  }

  &:hover {
    @include hover-effect;
  }

  &__action {
    &:focus {
      @include hover-effect;
    }
  }

  &__image {
    height: 156px;
    position: relative;
    transition: $transition;
    width: 100%;

    @include mixins.breakpoint('medium') {
      height: 280px;
    }

    &::after {
      background-color: rgb(0 0 0 / 50%);
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: $transition;
      width: 100%;
    }
  }

  &__number {
    bottom: var(--spacing-20);
    color: var(--mdrt-color-text-on-dark-primary);
    height: 48px;
    position: absolute;
    right: calc(var(--spacing-8) * -1);
    transition: $transition;
    width: 76px;
    z-index: 1;

    @include mixins.breakpoint('medium') {
      height: 76px;
      width: 119px;
    }
  }

  &__type-badge {
    left: var(--spacing-12);
    position: absolute;
    top: var(--spacing-12);

    @include mixins.breakpoint('medium') {
      left: var(--spacing-24);
      top: var(--spacing-24);
    }
  }

  &__type-icon {
    height: var(--mdrt-size-s);
    width: var(--mdrt-size-s);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
    height: 100%;
  }

  &__bookmark-icon {
    color: tokens.$mdrt-icon-button-icon-fill;
    height: var(--mdrt-size-m);
    margin: tokens.$mdrt-icon-button-padding;
    width: var(--mdrt-size-m);
  }

  &__title {
    $_max-lines: 3;

    @include mixins.line-clamp($_max-lines);

    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-special-text-button-2);
    height: #{$_max-lines}lh;
    margin: 0;
    text-overflow: ellipsis;
    word-wrap: break-word;

    @include mixins.breakpoint('medium') {
      $_max-lines: 2;

      @include mixins.line-clamp($_max-lines);

      font: var(--mdrt-headline-4);
      height: #{$_max-lines + 0.05}lh;
    }
  }

  &__info {
    align-items: flex-end;
    color: var(--mdrt-color-text-on-light-secondary);
    display: flex;
    font: var(--mdrt-body-4);
    height: 1lh;
  }

  &__authors {
    display: none;
    gap: var(--spacing-4);
    margin: 0;

    @include mixins.breakpoint('medium') {
      display: flex;
    }
  }

  &__first-author {
    @include mixins.line-clamp(1);

    text-overflow: ellipsis;
    word-break: break-all;
  }

  &__other-authors {
    flex: 0 0 auto;
  }

  &__read-time {
    align-items: center;
    display: inline-flex;
    flex-shrink: 0;

    &--with-separator {
      &::before {
        @include mixins.breakpoint('medium') {
          background-color: var(--mdrt-color-text-on-light-disabled);
          border-radius: 50%;
          content: '';
          display: inline-block;
          height: 4px;
          margin-inline: var(--spacing-4);
          width: 4px;
        }
      }
    }
  }

  &__description {
    @include mixins.line-clamp(3);

    color: var(--mdrt-color-text-on-light-secondary);
    font: var(--mdrt-title-4);
    margin: 0;
    opacity: 0;
    text-overflow: ellipsis;
    transform: translateY(20px);
    transition: all 0.15s ease-in;
    word-wrap: break-word;

    @include mixins.breakpoint('medium') {
      font: var(--mdrt-title-3);
    }
  }
}
