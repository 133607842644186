@use '@shared/util-styling/mixins';

.message {
  align-items: center;
  background-color: var(--mdrt-color-backgrounds-bg-surface-02);
  border-radius: var(--mdrt-corner-radius-m);
  color: var(--mdrt-color-text-on-light-primary);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);
  margin: var(--spacing-12) 0 var(--spacing-16);
  padding: var(--spacing-16);
  text-align: center;
  width: 100%;

  @include mixins.breakpoint('medium') {
    padding-block: var(--spacing-32);
  }

  &__header {
    font: var(--mdrt-headline-4);

    @include mixins.breakpoint('medium') {
      font: var(--mdrt-headline-3);
    }
  }

  &__text {
    font: var(--mdrt-title-3);
  }

  &__button {
    display: inline-block;
  }
}
