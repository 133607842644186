@use '@shared/util-styling/mixins';

.table-actions {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-20);
  width: 100%;

  @include mixins.breakpoint('small') {
    width: fit-content;
  }

  @include mixins.breakpoint('medium') {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}

.filter-select {
  font-size: 1.6rem;
}
