@use '../../../../../../shared/util-styling/mixins';

@mixin page-layout {
  padding: 0 var(--spacing-10);
  width: 100%;

  @include mixins.breakpoint('medium') {
    padding: 0 var(--spacing-20);
  }
}
