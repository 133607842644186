@use '@shared/util-styling/mixins';

.wrapper {
  position: relative;
  width: 100%;
}

.search-bar {
  align-items: center;
  border-bottom: var(--mdrt-color-borders-dark-02) var(--mdrt-border-thickness-02) solid;
  display: flex;
  transition: border var(--animation-duration) ease-in-out;

  &:focus-within {
    border-color: var(--mdrt-color-borders-dark-04);
  }
}

// Increased specificity because of a known Next.js CSS order issue -
// https://github.com/vercel/next.js/issues/51030
input.input {
  border: none;
  color: var(--mdrt-color-text-on-light-primary);
  font: var(--mdrt-headline-4);
  height: 48px;
  padding-left: var(--spacing-40);

  @include mixins.breakpoint('small') {
    font: var(--mdrt-headline-2);
    height: 64px;
    padding-left: var(--spacing-48);
  }

  &::placeholder {
    color: var(--mdrt-color-text-on-light-placeholder);
  }

  &:hover {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;

    &::placeholder {
      color: var(--mdrt-color-text-on-light-disabled);
    }
  }
}

.input-icon {
  color: var(--mdrt-color-text-on-light-primary);
  margin-left: -12px;

  @include mixins.breakpoint('small') {
    height: 32px;
    width: 32px;
  }
}

.buttons-container {
  display: flex;
  gap: var(--spacing-8);
  z-index: var(--z-index-content);
}

.clear-button {
  @include mixins.button-reset;

  background: var(--mdrt-color-backgrounds-bg-neutral-fill);
  border-radius: var(--mdrt-corner-radius-l);
  color: var(--mdrt-color-text-on-light-tertiary);
  height: var(--mdrt-size-l);
  padding: var(--spacing-6);
  transition: background-color var(--animation-duration) ease-out;
  width: var(--mdrt-size-l);

  &:hover {
    background: var(--mdrt-color-backgrounds-bg-neutral-hover);
    color: var(--mdrt-color-text-on-light-secondary);
  }
}
