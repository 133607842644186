@use '@shared/util-styling/mixins';
@use '@shared/util-styling/tokens';

.preview-modal {
  $_border-style: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-02);

  @include mixins.modal-size-small;

  &__description-section {
    border-bottom: $_border-style;
    color: var(--mdrt-color-text-on-light-secondary);
    margin: var(--spacing-8) 0 var(--spacing-16) 0;
    padding-bottom: var(--spacing-24);
  }

  &__form-preview-section-heading {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: var(--spacing-12) 0 var(--spacing-4) 0;
  }

  &__form-preview-section-title {
    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-title-2);
  }

  &__edit-icon {
    height: tokens.$mdrt-icon-button-icon-size;
    width: tokens.$mdrt-icon-button-icon-size;
  }

  &__edit-button {
    @include mixins.button-icon;
  }

  &__form-preview-items-list {
    border-bottom: $_border-style;
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}
