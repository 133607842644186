@use '@shared/util-styling/mixins';

.person-page {
  padding: 0 var(--spacing-10);
  width: 100%;

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--spacing-20);
    margin-top: var(--spacing-40);
  }

  &__two-columns-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-20);
    margin-top: var(--spacing-20);
  }
}

@include mixins.breakpoint('small') {
  .person-page {
    padding: 0 var(--spacing-20);
  }
}

@include mixins.breakpoint('medium') {
  .person-page {
    &__two-columns-wrapper {
      display: grid;
      gap: var(--spacing-40);
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}
