.filter-footer {
  align-content: center;
  border-top: var(--mdrt-border-thickness-02) solid var(--mdrt-color-borders-dark-02);
  display: flex;
  flex-direction: row;
  gap: var(--mdrt-spacing-12);
  justify-content: space-between;
  min-height: var(--search-results-footer-height);
  padding: var(--mdrt-spacing-24) var(--mdrt-spacing-16);

  &__apply-button {
    flex-grow: 1;
    justify-content: center;
  }
}
