@use '@shared/util-styling/mixins';

.form {
  display: grid;
  grid-gap: var(--spacing-24) var(--spacing-16);
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(6, 1fr);

  @include mixins.breakpoint('medium') {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }

  &__search-field-container {
    grid-area: auto;

    @include mixins.breakpoint('medium') {
      grid-area: 1 / 1 / 2 / 4;
    }
  }

  &__city-field-container {
    grid-area: auto;

    @include mixins.breakpoint('medium') {
      grid-area: 2 / 1 / 3 / 2;
    }
  }

  &__state-field-container {
    grid-area: auto;

    @include mixins.breakpoint('medium') {
      grid-area: 2 / 2 / 3 / 3;
    }
  }

  &__country-field-container {
    grid-area: auto;

    @include mixins.breakpoint('medium') {
      grid-area: 2 / 3 / 3 / 4;
    }
  }

  &__membership-type-field-container {
    grid-area: auto;

    @include mixins.breakpoint('medium') {
      grid-area: 3 / 1 / 4 / 4;
    }
  }

  &__search-button-container {
    grid-area: auto;

    @include mixins.breakpoint('medium') {
      grid-area: 4 / 1 / 5 / 4;
    }
  }

  &__button {
    width: 100%;
  }
}
