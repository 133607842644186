@use '@shared/util-styling/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  padding: var(--spacing-56) 0;
  border-bottom: 1px solid var(--mdrt-color-borders-dark-02);

  &__description {
    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-body-3-long);
  }

  &__label {
    font: var(--mdrt-headline-2);
    color: var(--mdrt-color-text-on-light-primary);
  }
}

.membership-dues-table {
  min-width: 672px;

  &:focus-visible {
    outline: 2px solid;
    outline-offset: 0;
  }
}
