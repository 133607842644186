@use '@shared/util-styling/mixins';

.content-wrapper {
  background-color: var(--mdrt-color-backgrounds-bg-surface-02);
  border-top: 1px solid var(--mdrt-color-borders-dark-01);
  margin-top: var(--spacing-24);
  padding-top: var(--spacing-24);

  @include mixins.breakpoint('medium') {
    padding-top: var(--spacing-32);
  }
}
