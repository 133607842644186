@use '@shared/util-styling/mixins';

.upload-photo-dialog-actions {
  $_padding-size-tertiary: 8px;

  @include mixins.button-group-base;

  justify-content: space-between;
  padding: $_padding-size-tertiary 0;
  width: 100%;

  &__delete-button-container {
    @include mixins.button-group-base;

    align-self: center;
  }

  &__button-group-container {
    @include mixins.button-group-base;

    justify-content: flex-end;
  }
}
