@use '@shared/util-styling/tokens';

.button {
  width: fit-content;
}

.internal-icon {
  background: tokens.$mdrt-buttons-primary-bg-fill;
  border-radius: 50%;
}
