.donation-chart {
  overflow-x: auto;

  &__wrapper {
    height: 115px;
    min-width: 500px;
    width: 100%;

    &--scale-6 {
      min-width: var(--section-content-max-width);
    }

    &--scale-5 {
      min-width: 850px;
    }
  }
}
