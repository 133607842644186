.empty-placeholder {
  height: 150px;
}

.event-date {
  font: var(--mdrt-title-3);

  &--first {
    margin-bottom: 0.5rem;
  }
}
