@use '@shared/util-styling/tokens';
@use '@shared/util-styling/mixins';

.page-top-skeleton {
  margin: var(--spacing-16) 0;

  &__button {
    height: 40px;
    margin-bottom: var(--spacing-16);
    max-width: 170px;
  }

  &__heading {
    height: 56px;
    margin-bottom: var(--spacing-16);
    max-width: 380px;
  }

  &__text {
    height: 16px;
    margin-bottom: var(--spacing-4);
    max-width: 740px;
  }

  @include mixins.breakpoint('small') {
    &__text {
      height: 24px;
    }
  }
}

.page-top-skeleton-tabs-section {
  display: flex;
  gap: var(--spacing-8);
  margin: var(--spacing-16) 0;
  overflow: hidden;

  &__tab {
    border-radius: var(--mdrt-corner-radius-m);
    height: 116px;
    width: 116px;
  }
}

.tab-panel-skeleton {
  max-width: 740px;

  &__heading {
    height: 32px;
    margin-bottom: var(--spacing-12);
    max-width: 220px;
  }

  &__text {
    height: 16px;
    margin-bottom: var(--spacing-4);
    width: 100%;
  }

  &__question {
    &:first-child {
      margin-top: var(--spacing-32);
    }

    height: 16px;
    width: 100%;
  }

  &__slider {
    height: 40px;
    margin-top: var(--spacing-12);
    width: 100%;
  }

  @include mixins.breakpoint('small') {
    &__question,
    &__slider {
      margin-left: var(--spacing-40);
      width: calc(100% - var(--spacing-40));
    }
  }
}
