.filter {
  display: flex;
  gap: var(--spacing-8);

  &__item-list {
    display: flex;
    gap: var(--spacing-8);
  }

  &__item {
    white-space: nowrap;
  }
}
