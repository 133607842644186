.label {
  align-items: center;
  display: flex;
  gap: var(--spacing-12);
  margin: calc(var(--spacing-8) * -1) 0;

  &__badge {
    height: 40px;
    width: 40px;
    flex-shrink: 0;
  }
}
