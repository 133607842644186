@use '@shared/util-styling/mixins';

.section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  margin: var(--spacing-16) 0 64px 0;

  @include mixins.breakpoint('medium') {
    flex-direction: row;
  }

  &__title {
    margin-bottom: var(--spacing-24);
  }

  &__primary-content {
    flex-grow: 1;
    width: 100%;

    @include mixins.breakpoint('medium') {
      max-width: 50%;
    }
  }

  &__secondary-content {
    flex-grow: 1;
  }
}

.search-form {
  padding: var(--spacing-24);
}
