@use '@shared/util-styling/mixins';

.image {
  height: 340px;
  object-fit: cover;
  width: 100%;

  &--compact {
    height: 180px;
  }

  @include mixins.breakpoint('small') {
    height: auto;
  }

  @include mixins.breakpoint('medium') {
    height: 340px;

    &--compact {
      height: 180px;
    }
  }
}
