@use '@shared/util-styling/mixins';

.section {
  margin: var(--spacing-56) 0 var(--spacing-32) 0;
  border-bottom: 1px solid var(--mdrt-color-borders-dark-02);
}

.profile {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  margin-bottom: var(--spacing-32);

  &__image {
    @include mixins.next-image-contained;
  }

  &__text-container {
    align-self: center;

    &--title {
      font: var(--mdrt-title-2);
      margin-bottom: var(--spacing-8);
    }

    &--name {
      font: var(--mdrt-headline-3);
      margin-bottom: var(--spacing-8);
    }

    p {
      font: var(--mdrt-body-3-long);
    }
  }

  &__image-container {
    align-self: start;
  }

  @include mixins.breakpoint('medium') {
    $this: &;
    flex-direction: row;
  }
}
