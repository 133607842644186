.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);

  .welcome,
  .designations {
    color: var(--mdrt-color-text-on-dark-secondary);
    font: var(--mdrt-title-1);
  }

  .full-name {
    color: var(--mdrt-color-text-on-dark-primary);
    font: var(--mdrt-headline-1);
  }

  .info-row {
    color: var(--mdrt-color-text-on-dark-secondary);
    display: flex;
    flex-direction: row;
    font: var(--mdrt-title-3);
  }

  .separator {
    padding: var(--spacing-4) var(--spacing-12);

    &__line {
      border-right: 1px solid var(--mdrt-color-text-on-dark-disabled);
      height: 100%;
    }
  }
}
