@use '@shared/util-styling/tokens';
@use '@shared/util-styling/mixins';

.actions {
  display: flex;
  justify-content: space-between;
  margin: var(--spacing-40) var(--spacing-56) 0;

  &__button-icon {
    background: tokens.$mdrt-buttons-primary-bg-fill;
    border-radius: 50%;
    height: 24px;
    padding: var(--spacing-4);
    width: 24px;
  }
}

.receipt {
  font: var(--mdrt-title-3);
  margin: var(--spacing-40) auto 0 auto;
  max-width: 800px;
  padding-inline: var(--spacing-40);
  width: 100%;

  &__header {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
    justify-content: space-between;
    margin-bottom: var(--spacing-40);

    h1 {
      font: var(--mdrt-title-2);
      font-family: sans-serif;
      text-transform: uppercase;
    }
  }

  &__section {
    width: 70%;

    & + & {
      margin-top: var(--spacing-24);
    }

    @include mixins.breakpoint('small', max) {
      width: 100%;
    }
  }

  &__section-title {
    color: var(--temp-color-neutral-dark);
    font-family: sans-serif;
    font-size: var(--font-size-xs);
    margin-bottom: var(--spacing-4);
  }

  &__section-content {
    background-color: transparent;
    padding: 0;

    hr {
      margin: var(--spacing-4) 0;
    }
  }

  &__section-item {
    align-items: center;
    color: var(--temp-color-neutral-gray-80);
    display: flex;
    font-size: var(--font-size-2xs);
    font-weight: var(--font-weight-medium);
    gap: var(--spacing-8);
    justify-content: space-between;

    & + & {
      margin-top: var(--spacing-4);
    }

    strong {
      color: var(--temp-color-neutral-dark);
      flex: 0 1 auto;
      font-family: sans-serif;
      font-weight: var(--font-weight-medium);
      text-align: left;
      width: 160px;
      word-break: break-word;
    }
  }

  &__section-item-value--capitalized {
    font-size: var(--font-size-2xs);
    text-transform: capitalize;

    & + & {
      margin-top: var(--spacing-4);
    }

    strong:nth-last-child(1) {
      text-align: left;
      width: 160px;
    }
  }
}

@media print {
  .actions {
    display: none;
  }

  .receipt {
    margin: 0;
  }
}
