.dead-letters-table-wrapper {
  margin-top: var(--spacing-20);
  max-height: 500px;
  overflow: auto;

  &__table {
    margin: 0;
  }
}

.table-title {
  align-self: normal;

  &__bold {
    font-weight: var(--font-weight-bold);
  }
}
