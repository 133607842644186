@use '@shared/util-styling/mixins';

.learn-hero-carousel-card {
  $this: &;

  display: flex;
  flex-flow: column-reverse nowrap;
  gap: var(--spacing-24);
  justify-content: space-between;
  transition: opacity 0.2s ease-in;

  :global(.react-multi-carousel-item--active + li) & {
    @include mixins.breakpoint('small', max) {
      #{$this}__image {
        transform: translateX(#{calc(var(--spacing-12) * -1)}) scaleY(0.9);
      }
    }
  }

  :global(.react-multi-carousel-item:not(.react-multi-carousel-item--active)) & {
    @include mixins.breakpoint('small') {
      opacity: 0;
    }

    #{$this}__content {
      @include mixins.breakpoint('small', max) {
        opacity: 0;
      }
    }
  }

  :global(.react-multi-carousel-item--active:last-of-type) & {
    @include mixins.breakpoint('small', max) {
      #{$this}__image {
        transform: scaleX(1.04);
      }
    }
  }

  @include mixins.breakpoint('small') {
    align-items: center;
    flex-direction: row;
  }

  &__image {
    align-self: flex-start;
    aspect-ratio: 1 / 1;
    border-radius: var(--mdrt-corner-radius-l);
    height: auto;
    max-width: 100%;
    object-fit: cover;
    position: relative;
    transform-origin: left;
    transition: all 0.2s ease-in;
    width: 99%;

    @include mixins.breakpoint('small') {
      align-self: center;
      margin-right: calc(var(--spacing-12) * -1);
      max-width: 60%;
      width: 334px;
    }

    @include mixins.breakpoint('medium') {
      height: 400px;
      max-width: none;
      width: 400px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
    height: 100%;
    transition: opacity 0.2s ease-in;

    @include mixins.breakpoint('small') {
      width: 50%;
    }
  }

  &__title {
    $_max-lines: 2;

    @include mixins.line-clamp($_max-lines);

    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-headline-3);
    height: #{$_max-lines + 0.05}lh;
    margin: 0;
    text-overflow: ellipsis;

    @include mixins.breakpoint('medium') {
      font: var(--mdrt-headline-1);
      height: auto;
    }
  }

  &__info {
    color: var(--mdrt-color-text-on-light-tertiary);
    display: flex;
    font: var(--mdrt-title-4);
    height: 1lh;

    @include mixins.breakpoint('medium') {
      font: var(--mdrt-title-3);
      height: auto;
    }
  }

  &__authors {
    display: flex;
    gap: var(--spacing-4);
    margin: 0;
  }

  &__first-author {
    @include mixins.line-clamp(1);

    text-overflow: ellipsis;
    word-break: break-all;
  }

  &__other-authors {
    flex: 0 0 auto;
  }

  &__read-time {
    align-items: center;
    display: inline-flex;
    flex-shrink: 0;

    &--with-separator {
      &::before {
        background-color: var(--mdrt-color-text-on-light-disabled);
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 4px;
        margin-inline: var(--spacing-4);
        width: 4px;
      }
    }
  }

  &__description {
    $_max-lines: 2;

    @include mixins.line-clamp($_max-lines);

    color: var(--mdrt-color-text-on-light-secondary);
    font: var(--mdrt-title-3);
    height: #{$_max-lines}lh;
    margin: 0;
    text-overflow: ellipsis;

    @include mixins.breakpoint('medium') {
      @include mixins.line-clamp(3);

      font: var(--mdrt-title-2);
      height: auto;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-8);
    padding-top: var(--spacing-16);
  }
}
