@use '@shared/util-styling/mixins';

$_color-overlay-text: rgba(255, 255, 255, 0.8);
$_color-overlay-background: rgba(28, 28, 28, 0.502);
$_transition_opacity: opacity 0.3s ease;

.photo-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--mdrt-color-backgrounds-bg-surface-03);
  border-radius: var(--mdrt-corner-radius-maximum);
  overflow: hidden;
  object-fit: cover;
  position: relative;

  &__image {
    display: block;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-4);
    font: var(--mdrt-title-4);
    color: var(--mdrt-color-text-on-light-tertiary);

    &--hover {
      color: $_color-overlay-text;
      position: absolute;
      background-color: $_color-overlay-background;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: $_transition_opacity;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__content--hover &__icon {
    color: $_color-overlay-text;
  }

  &__icon {
    height: 32px;
    color: var(--mdrt-color-text-on-light-placeholder);

    @include mixins.breakpoint('medium') {
      height: 38px;
    }
  }
}
