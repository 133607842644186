@use '@shared/util-styling/mixins';

.quiz-tab-item {
  @include mixins.button-reset;

  &:focus {
    outline: none;
  }

  &--focus-visible {
    &:focus {
      outline: auto;
    }
  }
}
