@use '@shared/util-styling/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  padding: var(--spacing-56) 0;
  border-bottom: 1px solid var(--mdrt-color-borders-dark-02);

  p {
    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-body-3-long);
  }

  &__label {
    font: var(--mdrt-headline-2);
    color: var(--mdrt-color-text-on-light-primary);
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-12);
  }

  &__link-skeleton {
    height: 36px;
  }
}
