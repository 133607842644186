@use '@shared/util-styling/tokens';
@use '@shared/util-styling/mixins';

$_topic-section-max-width: 740px;
$_quiz-tabs-list-height: 174px;

.quiz-page {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
  margin-block: var(--spacing-16);

  &__description-container {
    max-width: $_topic-section-max-width;
  }

  &__text {
    color: var(--mdrt-color-text-on-light-secondary);
    margin-top: var(--spacing-16);
  }

  &__button-icon {
    background: tokens.$mdrt-buttons-primary-bg-fill;
    border-radius: 50%;
    height: 24px;
    padding: var(--spacing-4);
    width: 24px;
  }
}

.quiz-tabs-list {
  background-color: var(--mdrt-color-backgrounds-bg-surface);
  display: flex;
  gap: var(--spacing-8);
  overflow-x: auto;
  padding: var(--spacing-16) 4px;
  position: sticky;
  top: 0;
  z-index: 2;

  @include mixins.breakpoint('medium') {
    top: var(--app-header-height-secondary);
  }
}

.topic-section-container {
  margin-block: var(--spacing-16);
  max-width: $_topic-section-max-width;
  scroll-margin-top: $_quiz-tabs-list-height;

  @include mixins.breakpoint('medium') {
    scroll-margin-top: calc(var(--app-header-height-secondary) + $_quiz-tabs-list-height);
  }
}

.topic-section {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-s);
  gap: var(--spacing-32);
  margin-bottom: var(--spacing-40);
}

.topic-section-header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);

  &__heading {
    font: var(--mdrt-headline-3);
    margin: 0;
  }

  &__text {
    color: var(--mdrt-color-text-on-light-secondary);
    margin: 0;
  }
}
