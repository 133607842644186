@use '@shared/util-styling/mixins';

.card-skeleton {
  border-radius: var(--mdrt-corner-radius-l);
  height: 264px;
  margin-right: var(--spacing-12);
  min-width: 43vw;

  @include mixins.breakpoint('small') {
    min-width: 30vw;
  }

  @include mixins.breakpoint('medium') {
    height: 396px;
    margin-right: var(--spacing-24);
    min-width: 300px;
  }

  * {
    display: block;
    height: 100%;
  }
}
