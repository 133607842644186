@use '@shared/util-styling/mixins';

.requirements-wrapper {
  position: relative;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);
  margin-bottom: var(--spacing-24);
  width: 100%;

  h2 {
    font: var(--mdrt-headline-2);
    color: var(--mdrt-color-text-on-light-primary);
  }

  &__dropdown-skeleton {
    height: 48px;
  }

  @include mixins.breakpoint('medium') {
    flex-direction: row;

    &__country-selector {
      margin-left: auto;
      width: 279px;
    }

    &__year-selector {
      width: 137px;
    }
  }
}

.production-table {
  min-width: 672px;

  &:focus-visible {
    outline: 2px solid;
    outline-offset: 0;
  }
}
