@use '@shared/util-styling/mixins';

@mixin divider {
  background-color: var(--mdrt-color-borders-dark-02);
  height: 1px;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;

  @include mixins.breakpoint('medium') {
    align-items: center;
    flex-flow: row nowrap;
  }

  & + & {
    margin-top: var(--spacing-24);
  }
}

.content-wrapper {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  gap: var(--spacing-12);
  width: 100%;
}

.content {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  @include mixins.breakpoint('medium') {
    align-items: center;
    flex-flow: row nowrap;
  }
}

.info {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
}

.member,
.title {
  color: var(--mdrt-color-text-on-light-primary);
  font: var(--mdrt-title-3);
}

.address {
  color: var(--mdrt-color-text-on-light-tertiary);
  font: var(--mdrt-title-4);
}

.actions {
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  gap: var(--spacing-8);
  margin-top: var(--spacing-8);

  @include mixins.breakpoint('medium') {
    margin-top: 0;
  }
}

.action {
  @include mixins.button-icon;
}

.modal {
  $_max-width: 525px;
  $_padding-block: var(--mdrt-spacing-16);

  background-color: var(--mdrt-color-backgrounds-bg-surface);
  border-radius: var(--mdrt-corner-radius-l) var(--mdrt-corner-radius-l) 0 0;
  bottom: 0;
  display: flex;
  max-height: 90vh;
  padding: $_padding-block var(--mdrt-spacing-24);
  position: fixed;
  width: 100%;

  @include mixins.breakpoint('small') {
    border-bottom-left-radius: var(--mdrt-corner-radius-l);
    border-bottom-right-radius: var(--mdrt-corner-radius-l);
    max-width: $_max-width;
    position: relative;
  }

  @include mixins.breakpoint('medium') {
    max-width: none;
    width: $_max-width;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-12);
    padding-top: var(--spacing-8);
    width: 100%;

    @include mixins.breakpoint('small') {
      max-height: calc(602px - (#{$_padding-block} * 2));
    }
  }

  &__header {
    display: flex;
    gap: var(--spacing-12);
  }

  &__close-button {
    align-self: flex-start;
  }

  &__content {
    flex: 1 1 auto;
    font: var(--mdrt-body-3-long);
    overflow-y: auto;
  }

  &__footer {
    align-items: flex-start;
    display: inline-flex;
    gap: var(--spacing-12);
  }

  &__buttons {
    width: auto;
  }
}

.divider {
  @include divider;
}

.author-link {
  color: var(--mdrt-color-text-on-light-primary);
  text-decoration: none;
}
