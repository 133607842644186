@use '@shared/util-styling/mixins';

.photo-skeleton {
  height: 180px;
  width: 180px;

  @include mixins.breakpoint('medium') {
    height: 232px;
    width: 232px;
  }
}
