.filter-header {
  align-items: center;
  border-bottom: 2px solid var(--mdrt-color-borders-dark-02);
  display: flex;
  justify-content: space-between;
  padding: var(--mdrt-spacing-16) 0;

  &__text {
    font: var(--mdrt-title-2);
  }

  &__close-button {
    padding: var(--mdrt-spacing-06);

    svg {
      height: 20px;
      width: 20px;
    }
  }
}
