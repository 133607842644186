@use '@shared/util-styling/mixins';
@use '@shared/util-styling/tokens';

@mixin separator {
  align-items: center;
  display: inline-flex;

  &::after {
    background-color: var(--mdrt-color-text-on-light-placeholder);
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 4px;
    margin-inline: var(--spacing-8);
    width: 4px;
  }
}

.header {
  margin-top: var(--spacing-24);

  &__author-avatars {
    display: none;
    margin-right: var(--spacing-12);

    @include mixins.breakpoint('medium') {
      display: inline;
    }
  }

  &__author-avatar {
    position: relative;
    vertical-align: middle;

    & + & {
      margin-left: calc(var(--spacing-12) * -1);
    }
  }

  @for $i from 1 through 10 {
    &__author-avatar--zindex-#{$i} {
      z-index: #{$i};
    }
  }

  &__author-link {
    height: var(--spacing-24);
  }

  &__top {
    color: var(--mdrt-color-text-on-light-tertiary);
    font: var(--mdrt-title-3);
    margin-bottom: var(--spacing-24);
  }

  &__author-wrapper {
    @include separator;
  }

  &__sources {
    > * {
      @include separator;

      display: inline;

      &::after {
        bottom: 3px;
        position: relative;
      }
    }
  }

  &__date {
    display: inline-flex;
  }

  &__sources,
  &__date {
    padding: var(--spacing-8) 0;
  }

  &__heading,
  &__subheading {
    color: var(--mdrt-color-text-on-light-primary);
  }

  &__heading {
    font: var(--mdrt-headline-2);

    @include mixins.breakpoint('medium') {
      font: var(--mdrt-headline-1);
    }
  }

  &__subheading {
    font: var(--mdrt-title-2);
    margin-top: var(--spacing-16);

    * {
      line-height: inherit;
    }
  }

  &__bottom {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16);
    margin-top: var(--spacing-24);

    @include mixins.breakpoint('medium') {
      flex-flow: row nowrap;
      gap: var(--spacing-24);
    }
  }

  &__topics {
    display: inline-flex;
    flex-wrap: wrap;
    gap: var(--spacing-8);
  }

  &__actions {
    display: flex;
    flex-shrink: 0;
    flex-wrap: nowrap;
    gap: var(--spacing-8);
    line-height: 1;

    @include mixins.breakpoint('medium') {
      margin-left: auto;
    }
  }
}
