@use '@shared/util-styling/mixins';

.carousel-arrow-buttons {
  $_this: &;

  @include mixins.button-group-wrapper;

  display: none;
  margin-left: auto;

  @include mixins.breakpoint('small') {
    display: flex;
  }

  @include mixins.device('touch') {
    display: none;
  }

  &__button {
    @include mixins.button;

    background: var(--nav-arrow-button-bg-fill);
    border: none;
    color: var(--nav-arrow-button-color);
    height: var(--nav-arrow-button-height);
    padding: 0;
    width: var(--nav-arrow-button-width);

    svg {
      width: var(--nav-arrow-button-icon-width);
    }

    &:disabled {
      background: var(--mdrt-color-backgrounds-bg-neutral-hover);
    }

    &:not(:disabled):hover {
      background: var(--nav-arrow-button-bg-hover-fill);
      color: var(--nav-arrow-button-color);
    }
  }

  &__arrow {
    &--right {
      transform: rotate(180deg);
    }
  }

  &--secondary {
    background: var(--nav-arrow-button-border-secondary-fill);
    border: var(--nav-arrow-button-border-secondary-fill);

    #{$_this}__button {
      background: var(--nav-arrow-button-bg-secondary-fill);
      color: var(--nav-arrow-button-secondary-color);

      &:disabled {
        background: var(--nav-arrow-button-bg-secondary-disabled);
      }

      &:not(:disabled):hover {
        background: var(--nav-arrow-button-bg-hover-secondary-fill);
        color: var(--nav-arrow-button-color);
      }
    }
  }
}
