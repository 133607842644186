@use '@shared/util-styling/mixins';

.banner {
  align-items: center;
  background-color: var(--mdrt-color-backgrounds-bg-surface-02);
  border-radius: var(--mdrt-corner-radius-m);
  color: var(--mdrt-color-text-on-light-primary);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
  justify-content: flex-start;
  margin-bottom: var(--spacing-8);
  padding: var(--spacing-24);

  @include mixins.breakpoint('medium') {
    flex-direction: row;
    gap: var(--spacing-24);
    justify-content: space-between;
  }

  &__message {
    font: var(--mdrt-title-3);
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    font: var(--mdrt-special-text-button-2);
    gap: var(--spacing-8);
  }

  &__button {
    font: var(--mdrt-special-text-button-2);
    text-wrap: nowrap;
  }
}
