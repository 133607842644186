@use '../mixins/responsive' as responsive;
@use '../mixins/next-image-contained' as *;

@mixin divider {
  background-color: var(--mdrt-color-borders-dark-01);
  height: 1px;
  margin-block: var(--spacing-24);
  width: 100%;
}

@mixin learn-detail-page {
  .wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: var(--spacing-24);

    @include responsive.breakpoint('medium') {
      margin-inline: auto;
      max-width: 844px;
      padding-bottom: var(--spacing-56);
    }
  }

  .hero {
    $_hero-height: 440px;

    background-color: var(--mdrt-color-backgrounds-bg-image);
    margin-left: calc(var(--container-spacing-inline) * -1);
    overflow: hidden;
    position: relative;
    width: calc(100% + var(--container-spacing-inline) * 2);

    @include responsive.breakpoint('medium') {
      height: $_hero-height;
      margin-left: 0;
      width: auto;
    }

    &__image {
      @include next-image-contained;
    }

    &--hide {
      @include responsive.breakpoint('medium') {
        height: 0;
        opacity: 0;
      }
    }
  }

  .content {
    font: var(--mdrt-body-3-long);
    padding-top: var(--spacing-16);

    @include responsive.breakpoint('medium') {
      padding-top: var(--spacing-24);
    }
  }

  .blocks {
    > ::before {
      @include divider;

      content: '';
      display: block;
      margin-bottom: var(--spacing-16);
    }
  }

  .divider {
    @include divider;
  }

  .accordion-content {
    margin-top: var(--spacing-24);
  }

  .audio-player {
    margin-top: var(--spacing-24);
  }
}
