@use '@shared/util-styling/mixins';

.section {
  align-items: center;
  border: none;
  color: var(--temp-color-neutral-dark);
  display: flex;
  flex-flow: column wrap;
  font-size: 10px;
  justify-content: space-between;
  margin-bottom: var(--spacing-16);
  margin-top: 0;
  padding: 0;
  width: 100%;

  &__title {
    color: var(--temp-color-neutral-dark);
    font-size: 16px;
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--spacing-16);
    margin-top: var(--spacing-16);
    text-align: center;
    width: 100%;
  }

  &__contact {
    display: flex;
    justify-content: space-between;
    width: 100%;

    p {
      display: inline-block;
    }
  }

  &__logo-wrapper {
    align-self: flex-start;
    color: var(--temp-color-neutral-dark);
    width: 100%;

    p {
      display: none;
    }
  }

  &__logo {
    color: inherit;
    margin-bottom: var(--spacing-8);
    width: 116px;
  }

  &__address {
    color: var(--temp-color-neutral-dark);

    p {
      line-height: 1.6;
    }
  }

  &__phone {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
    text-align: right;
  }
}
