@use '@shared/util-styling/mixins';

.profile-photo-container {
  $_photo-container-size: 224px;
  $_photo-container-size-small: 172px;
  $_badge-size-small: 64px;
  $_badge-size: 80px;

  background-color: var(--mdrt-color-borders-light-01);
  border: var(--mdrt-border-thickness-03) solid var(--mdrt-color-borders-light-01);
  border-radius: var(--mdrt-corner-radius-maximum);
  box-sizing: content-box;
  height: $_photo-container-size-small;
  min-height: $_photo-container-size-small;
  min-width: $_photo-container-size-small;
  position: relative;
  width: $_photo-container-size-small;

  &__button {
    @include mixins.button-reset;

    align-items: center;
    background-color: var(--mdrt-color-backgrounds-bg-surface-03);
    border-radius: var(--mdrt-corner-radius-maximum);
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  &__badge {
    align-items: center;
    border-radius: 50%;
    bottom: 0;
    display: flex;
    height: $_badge-size-small;
    justify-content: center;
    position: absolute;
    right: 0;
    width: $_badge-size-small;
  }

  @include mixins.breakpoint('medium') {
    height: $_photo-container-size;
    min-height: $_photo-container-size;
    min-width: $_photo-container-size;
    width: $_photo-container-size;

    &__badge {
      height: $_badge-size;
      width: $_badge-size;
    }
  }
}
