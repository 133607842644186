@use '@shared/util-styling/tokens';

.button {
  font: var(--mdrt-title-3);
  margin-top: var(--spacing-12);

  &__icon {
    background: tokens.$mdrt-buttons-primary-bg-fill;
    border-radius: 50%;
    height: 24px;
    padding: var(--spacing-4);
    width: 24px;
  }
}
