@use '@shared/util-styling/mixins';

.section {
  margin: var(--spacing-48) 0;

  @include mixins.breakpoint('medium') {
    margin: var(--spacing-56) 0;
  }

  &--separator-top {
    border-top: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-02);
    padding-top: var(--spacing-48);
    margin-top: 0;

    @include mixins.breakpoint('medium') {
      padding-top: var(--spacing-56);
    }
  }

  &--separator-bottom {
    border-bottom: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-02);
    padding-bottom: var(--spacing-48);
    margin-bottom: 0;

    @include mixins.breakpoint('medium') {
      padding-bottom: var(--spacing-56);
    }
  }

  &__heading {
    font: var(--mdrt-headline-2);
    color: var(--mdrt-color-text-on-light-primary);
    margin-bottom: var(--spacing-24);

    &--with-description {
      margin-bottom: var(--spacing-16);
    }
  }

  &__subheading {
    font: var(--mdrt-title-1);
    margin-bottom: var(--spacing-16);
  }

  &__description {
    font: var(--mdrt-title-2);
    color: var(--mdrt-color-text-on-light-secondary);
    margin-bottom: var(--spacing-32);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-32);

    @include mixins.breakpoint('medium') {
      flex-direction: row;
    }
  }
}
