@use '@shared/util-styling/mixins';

.carousel {
  $_spacing-between-items-mobile: var(--spacing-12);
  $_spacing-between-items-desktop: var(--spacing-24);
  $_spacing-heading-padding: var(--spacing-24);

  // making sure the property is always overridden by adding a global class, due to known NEXT issue https://github.com/vercel/next.js/issues/51030
  &__container:global(.react-multi-carousel-list) {
    margin-right: calc($_spacing-heading-padding + $_spacing-between-items-mobile);

    @include mixins.breakpoint('medium') {
      margin-right: $_spacing-heading-padding;
    }
  }

  // making sure the property is always overridden by adding a global class, due to known NEXT issue https://github.com/vercel/next.js/issues/51030
  &__item:global(.react-multi-carousel-item) > * {
    width: calc(100% - $_spacing-between-items-mobile);

    @include mixins.breakpoint('medium') {
      width: calc(100% - $_spacing-between-items-desktop);
    }
  }

  &__filters {
    display: flex;
    gap: var(--spacing-8);
  }

  &__filter {
    white-space: nowrap;
  }
}
