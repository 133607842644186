.wrapper {
  background-color: var(--mdrt-color-backgrounds-bg-surface);
  border-radius: var(--mdrt-corner-radius-m);
  bottom: calc(var(--spacing-8) * -1);
  box-shadow: var(--mdrt-elevation-02);
  display: flex;
  flex-direction: column;
  left: 0;
  max-height: 30vh;
  min-height: 160px;
  overflow-y: auto;
  padding: var(--spacing-8);
  position: absolute;
  right: 0;
  transform: translateY(100%);
  width: 100%;
  z-index: 1;
}

.title {
  color: var(--mdrt-color-text-on-light-tertiary);
  font: var(--mdrt-title-3);
  padding-inline: var(--spacing-8);
  padding-top: var(--spacing-8);
}

.suggestion {
  border-radius: var(--mdrt-corner-radius-m);
  color: var(--mdrt-color-text-on-light-primary);
  font: var(--mdrt-headline-4);
  justify-content: flex-start;
  padding: var(--spacing-12) var(--spacing-8);

  &:focus,
  &:hover {
    background-color: var(--mdrt-color-backgrounds-bg-neutral-hover);
  }
}
