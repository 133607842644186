@use '@shared/util-styling/mixins';

.hero-wrapper {
  font: var(--mdrt-title-2);
  margin: var(--mdrt-spacing-32) 0;

  @include mixins.breakpoint('medium') {
    margin: var(--mdrt-spacing-56) 0;
  }

  &--theme-secondary {
    background-color: var(--mdrt-color-backgrounds-bg-primary-fill-01);
    color: var(--mdrt-color-text-on-dark-primary);
    margin: 0;
    padding: var(--mdrt-spacing-32) 0;

    @include mixins.breakpoint('medium') {
      padding: var(--mdrt-spacing-56) 0;
    }
  }
}

.hero-content {
  align-items: center;
  display: grid;
  gap: var(--mdrt-spacing-24);

  &__image {
    @include mixins.next-image-contained;
  }

  &__headline {
    font: var(--mdrt-headline-2);
    margin-bottom: var(--spacing-16);
  }

  &__link-section {
    margin-top: var(--spacing-24);
  }

  &__call-to-action-section {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16);
    margin-top: var(--spacing-16);

    @include mixins.breakpoint('medium') {
      flex-direction: row;
    }
  }

  @include mixins.breakpoint('small') {
    &__text-section {
      padding-right: var(--spacing-24);
    }
  }

  @include mixins.breakpoint('medium') {
    gap: 0;
    grid-template-columns: repeat(2, 1fr);

    &__image-section {
      order: 1;
    }

    &__text-section {
      padding-right: var(--spacing-56);
    }
  }
}

.link-block {
  margin-bottom: var(--spacing-8);

  &--primary {
    margin-bottom: var(--spacing-24);
  }
}

.call-to-action-link-block {
  width: 100%;

  @include mixins.breakpoint('medium') {
    width: fit-content;
  }
}
