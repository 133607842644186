@use '@shared/util-styling/mixins';

.carousel-wrapper {
  overflow: hidden;
}

.carousel.carousel {
  overflow: visible;
  padding: var(--spacing-32) 0 var(--spacing-24) 0;
  width: calc(100% - var(--spacing-12));
}

.carousel-inner {
  margin: 0 auto;
  max-width: calc(var(--section-content-max-width) + (var(--container-spacing-inline) * 2));
  position: relative;
  width: 100%;
}

.carousel-graphics {
  display: none;
  position: absolute;
  z-index: -1;

  @include mixins.breakpoint('medium') {
    display: block;
  }

  &--pluses {
    bottom: 81px;
    height: 172px;
    right: 386px;
    width: 172px;
  }

  &--circles {
    bottom: 155px;
    height: 258px;
    right: -62px;
    width: 258px;
  }
}

.carousel-header {
  margin: 0;
}

.carousel-arrows.carousel-arrows {
  display: none;

  @include mixins.breakpoint('small') {
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-12);
    height: auto;
    margin-right: calc(var(--spacing-12) * -1);
    padding: 0;
    position: absolute;
    right: 0;
    top: calc(164px + 5vw);
    transform: translateX(50%);
    width: auto;
    z-index: 1;

    button {
      height: 40px;
      width: 40px;

      svg {
        width: 60%;
      }
    }
  }

  @include mixins.breakpoint('medium') {
    right: 0;
    top: 264px;
  }
}
