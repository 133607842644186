.form {
  $_photo-container-form-max-width: 450px;
  $_photo-container-form-max-width-wide: 636px;

  max-width: $_photo-container-form-max-width;

  &--wide {
    max-width: $_photo-container-form-max-width-wide;
  }
}
