.filter-stack {
  &__item {
    padding: var(--mdrt-spacing-08) var(--mdrt-spacing-06) var(--mdrt-spacing-08) 0;

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--mdrt-color-borders-dark-02);
    }
  }

  .switch-filter {
    width: 100%;
  }
}

.switch-filter {
  padding: var(--mdrt-spacing-12) 0;

  label {
    font: var(--mdrt-title-3);
  }
}
