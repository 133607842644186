@use '@shared/util-styling/mixins';

.link-wrapper {
  cursor: pointer;

  @include mixins.link-reset;
}

.container {
  display: grid;
  gap: var(--spacing-32);
  margin: 72px 0;

  &--first {
    margin-top: var(--spacing-28);
  }

  &__image-container {
    order: -1;
  }

  @include mixins.breakpoint('medium') {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-48);

    $this: &;

    &__image-container {
      order: 0;
    }

    &--reversed {
      #{$this}__image-container {
        order: -1;
      }
    }
  }

  &__heading {
    margin-bottom: var(--spacing-16);
    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-headline-2);
  }

  &__description {
    color: var(--mdrt-color-text-on-light-secondary);
    font: var(--mdrt-title-3);
    margin-bottom: var(--spacing-16);
  }

  &__details-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
    font: var(--mdrt-title-3);
  }

  &__label {
    color: var(--mdrt-color-text-on-light-tertiary);
  }

  &__image {
    display: block;
    object-fit: contain;
    width: 100%;
    height: auto;

    @include mixins.breakpoint('medium') {
      object-fit: cover;
      height: 338px;
    }
  }
}
