@use '@shared/util-styling/mixins';

.section {
  border-bottom: 1px solid var(--mdrt-color-borders-dark-02);
  margin-bottom: var(--spacing-56);
}

.accordion {
  margin-bottom: 32px;

  &__footer {
    position: absolute;
    bottom: -35px;
    left: 20px;
  }
}

.table {
  margin-top: var(--spacing-24);
  max-height: 552px;
  margin-bottom: -32px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 4fr;
  padding: 12px 0 0 0;
  border-bottom: 1px solid var(--mdrt-color-borders-dark-02);
}

.row:last-child {
  border-bottom: none;
}

.year {
  font: var(--mdrt-title-2);
  color: var(--mdrt-color-text-on-light-primary);
  grid-column: 1;
  grid-row: 1 / span 3;
  width: 20%;
}

.name {
  font: var(--mdrt-title-2);
  color: var(--mdrt-color-text-on-light-primary);

  grid-column: 2;
  grid-row: 1;
  margin-bottom: var(--spacing-8);
}

.title {
  grid-column: 2;
  grid-row: 2;
  margin-bottom: var(--spacing-8);
}

.location {
  grid-column: 2;
  grid-row: 3;
  margin-bottom: var(--spacing-8);
}

.title,
.location {
  font: var(--mdrt-title-2);
  color: var(--mdrt-color-text-on-light-tertiary);
}

@include mixins.breakpoint('medium') {
  .row {
    display: grid;
    grid-template-columns: 10% 1fr 1fr 1fr;
    padding: 12px 0 3px 32px;
  }

  .row:last-child {
    padding: 12px 0 0 32px;
  }

  .year {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
  }

  .name {
    grid-row: 1;
    grid-column: 2;
  }

  .title {
    grid-row: 1;
    grid-column: 3;
  }

  .location {
    grid-row: 1;
    grid-column: 4;
  }
}
