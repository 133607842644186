.input-preview {
  &__label {
    color: var(--mdrt-color-text-on-light-tertiary);
    font: var(--mdrt-body-4);
  }

  &__value {
    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-title-3);
    margin: 0 0 var(--spacing-12) 0;
  }
}
