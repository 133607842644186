.filter-header {
  color: var(--mdrt-color-text-on-light-primary);

  &--mobile {
    padding-inline: var(--spacing-16);
  }
}

.form {
  color: var(--mdrt-color-text-on-light-primary);
  flex-grow: 1;
  overflow-y: auto;

  &:not(&--mobile) {
    margin-left: calc(var(--spacing-6) * -1);
    padding-left: var(--spacing-6);
  }

  &--mobile {
    padding-inline: var(--spacing-16);
  }
}

.filter-footer {
  &--mobile {
    padding-bottom: var(--spacing-24);
  }
}
