.profile-photo-dialog-content {
  width: 100%;

  &__paragraph {
    padding-bottom: var(--spacing-16);
  }

  &__picture-requirements {
    color: var(--mdrt-color-text-on-light-tertiary);
    font: var(--mdrt-body-4);
  }

  &__photo-container {
    display: flex;
    justify-content: center;
    padding: var(--spacing-24);
  }

  &__photo {
    height: 224px;
    width: 224px;
  }

  &__error-message {
    margin-top: var(--spacing-24);
  }
}
