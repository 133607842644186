@use '@shared/util-styling/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  padding: var(--spacing-56) 0;
  border-bottom: 1px solid var(--mdrt-color-borders-dark-02);

  p {
    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-body-3-long);
  }

  &__label {
    font: var(--mdrt-headline-2);
    color: var(--mdrt-color-text-on-light-primary);
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-20);
    padding: 0;
    margin: var(--spacing-24) 0 0;
    counter-reset: item;
    list-style-type: none;

    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: var(--spacing-12);

      p {
        padding: var(--spacing-8) 0;
        color: var(--mdrt-color-text-on-light-primary);
        font: var(--mdrt-body-3-long);
      }
    }

    li:before {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      flex-shrink: 0;
      content: counter(item) ' ';
      color: var(--mdrt-color-text-on-light-primary);
      font: var(--mdrt-title-2);
      counter-increment: item;
      background-color: var(--mdrt-color-backgrounds-bg-surface-04);
      text-align: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-weight: 700;
    }
  }
}
